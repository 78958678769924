<template>
  <div id="settlesDetaisl">
    <a-modal
      width="53%"
      :visible="visible"
      :title="$t('pages_pay_104')"
      :mask-closable="false"
      @cancel="$emit('update:visible', false)"
    >
      <a-spin :spinning="spinLoading">
        <div class="detail-body-i">
          <div class="body-list">
            <div class="area-list" style="margin-top: 0">
              <div
                v-for="(item, index) in infoData.list"
                :key="index"
                class="area"
              >
                <span style="font-weight: 500">{{ item.name }}</span>
                <div class="text">
                  <img v-if="item.img" :src="item.img">
                  <a-tooltip v-if="['bankName', 'bankAccount', 'channelRespDesc'].includes(item.attr)" :title="item.value">
                    <div class="ellipsis">{{ item.value }}</div>
                  </a-tooltip>
                  <a-tooltip v-else-if="['remitAmount', 'arrivalAmount', 'feeAmt'].includes(item.attr)" :title="item.value">
                    <div class="ellipsis" style="width: 100px">{{ item.value }}</div>
                  </a-tooltip>
                  <span v-else>{{ item.value }}</span>
                </div>
              </div>
            </div>
            <div v-if="infoData.subList.length !== 0" class="area-list sub-list">
              <div
                v-for="(item, index) in infoData.subList"
                :key="index "
                class="area"
              >
                <span>{{ item.name }}</span>
                <div class="text">
                  <span>{{ item.value }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </a-spin>
      <template #footer>
        <a-button type="primary" @click="$emit('update:visible', false)">{{ $t('common_text_024') }}</a-button>
      </template>
    </a-modal>
  </div>
</template>
<script>
import { reactive, toRefs, watch } from 'vue'
import { getTradeStatusName, getCountryLogo, parseMoney } from '@/util'
import i18n from '@/locale'
import { _pay } from '@/api'
export default {
  name: 'SettlesDetaisl',
  props: {
    visible: {
      type: Boolean,
      default: () => { return false }
    },
    applyNo: {
      type: String,
      default: () => { return '' }
    }
  },
  setup (props) {
    const state = reactive({
      spinLoading: false,
      titles: [],
      infoData: {
        list: [],
        subList: []
      }
    })

    watch(() => props.applyNo,
      () => loadDetails()
    )

    const loadDetails = async () => {
      state.spinLoading = true
      const res = await _pay.getSingleDetail({ applyNo: props.applyNo })
      if (res.data.succ) {
        if (res.data.succ) {
          const { data } = res.data
          state.infoData.list = [
            { name: i18n.global.t('pages_pay_032'), value: data.remitAmount ? `${data.remitCurrency} ${parseMoney(data.remitAmount)}` : '-', img: getCountryLogo(data.remitCurrency), attr: 'remitAmount' },
            { name: i18n.global.t('pages_pay_085'), value: data.payeeName || '-' },
            { name: i18n.global.t('pages_pay_086'), value: data.rate },
            { name: i18n.global.t('pages_pay_087'), value: data.payeeBankName || '-', attr: 'bankName' },
            { name: i18n.global.t('pages_pay_081'), value: `${data.arrivalCurrency} ${parseMoney(data.arrivalAmount)}`, img: getCountryLogo(data.arrivalCurrency), attr: 'arrivalAmount' },
            { name: i18n.global.t('pages_pay_088'), value: data.payeeBankAcct || '-', attr: 'bankAccount' },
            { name: i18n.global.t('pages_pay_082'), value: `${data.feeCurrency} ${parseMoney(data.feeAmt)}`, img: getCountryLogo(data.feeCurrency), attr: 'feeAmt' },
            { name: i18n.global.t('pages_pay_030'), value: getTradeStatusName(data.tradeType, 'tradeCode') },
            { name: i18n.global.t('pages_pay_121'), value: getTradeStatusName(data.amountMark, 'amountMark') },
            { name: i18n.global.t('pages_pay_371'), value: data.channelRespDesc || '-', attr: 'channelRespDesc' }
          ]
          state.infoData.subList = [
            { name: i18n.global.t('pages_pay_089'), value: getTradeStatusName(data.subjectType, 'subjectType') },
            { name: i18n.global.t('pages_pay_090'), value: data.paymentType },
            { name: data.subjectType === '00' ? i18n.global.t('pages_pay_091') : i18n.global.t('pages_pay_092'), value: data.idCard },
            { name: i18n.global.t('pages_pay_093'), value: data.phone },
            { name: i18n.global.t('pages_pay_094'), value: data.address },
            { name: i18n.global.t('pages_pay_095'), value: data.purpose || '-' }
          ]
        }
      }
      state.spinLoading = false
    }

    return {
      ...toRefs(state)
    }
  }
}
</script>
<style lang="less" scoped>
@import '../../../styles/detail-form.less';
</style>

