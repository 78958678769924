<template>
  <div id="outboundDetails">
    <a-modal
      width="60%"
      :visible="visible"
      :title="$t('pages_pay_104')"
      :mask-closable="false"
      @cancel="$emit('update:visible', false)"
    >
      <a-spin :spinning="spinLoading">
        <div class="detail-body-i">
          <div class="body-list">
            <div class="title">
              <money-collect-filled class="ic-transact" />
              <span>{{ infoData.title }}</span>
            </div>
            <div class="area-list">
              <div
                v-for="(item, index) in infoData.list"
                :key="index"
                class="area"
              >
                <span style="font-weight: 500">{{ item.name }}</span>
                <div class="text">
                  <img v-if="item.img" :src="item.img">
                  <a-tooltip
                    v-if="['remitAmount', 'arrivalAmount', 'feeAmt'].includes(item.attr)"
                    :title="item.value"
                  >
                    <div class="ellipsis" style="width: 100px">{{ item.value }}</div>
                  </a-tooltip>
                  <a-tooltip
                    v-else-if="item.value"
                    :title="item.value"
                  >
                    <div>{{ item.value }}</div>
                  </a-tooltip>
                  <span v-else />
                </div>
              </div>
            </div>

            <div
              v-if="infoData.subList.length !== 0"
              class="area-list sub-list"
            >
              <div
                v-for="(item, index) in infoData.subList"
                :key="index "
                class="area"
              >
                <span>{{ item.name }}</span>
                <div class="text">
                  <a-tooltip
                    v-if="item.value"
                    :title="item.value"
                  >
                    <div class="ellipsis">{{ item.value }}</div>
                  </a-tooltip>
                  <span v-else />
                </div>
              </div>
            </div>

            <div
              v-if="gpiShow && status === '02'"
              style="padding: 24px 30px 0 30px"
              class="sub-list"
            >
              <div
                style="
                  font-size: 15px;
                  font-weight: 600;
                  margin-left: -4px;
                "
              >
                <span>{{ $t('pages_gpi_001') }}</span>
              </div>
              <a-popconfirm
                v-if="gpiList.length === 0"
                :visible="popVisible"
                :title="$t('pages_gpi_003', [fee.feeAmt, fee.feeCurrency])"
                @cancel="() => popVisible = false"
                @confirm="loadGpi"
              >
                <a-button
                  type="link"
                  style="margin-left: -19px"
                  :loading="viewLoading"
                  @click="loadFee"
                >{{ $t('pages_gpi_004') }}</a-button>
              </a-popconfirm>
              <a-skeleton
                v-if="skeletonLoading"
                active
              />
              <gpi-detail
                v-else
                from="payment"
                :list="gpiList"
              />
            </div>
          </div>
        </div>
      </a-spin>
      <template #footer>
        <a-button
          type="primary"
          @click="$emit('update:visible', false)"
        >{{ $t('common_text_024') }}</a-button>
      </template>
    </a-modal>
  </div>
</template>
<script>
import GpiDetail from '@/components/gpi-detail.vue'
import { reactive, toRefs, watch } from 'vue'
import { useStore } from 'vuex'
import { _pay, _receive } from '@/api'
import { getTradeStatusName, getCountryLogo, parseMoney } from '@/util'
import { message } from 'ant-design-vue'
import i18n from '@/locale'
export default {
  name: 'OutboundDetails',
  components: {
    'gpi-detail': GpiDetail
  },
  props: {
    visible: {
      type: Boolean,
      default: () => { return false }
    },
    applyNo: {
      type: String,
      default: () => { return '' }
    }
  },
  setup (props) {
    const store = useStore()
    const state = reactive({
      spinLoading: false,
      skeletonLoading: false,
      viewLoading: false,
      popVisible: false,
      gpiShow: false,
      gpiList: [],
      titles: [],
      fee: {},
      status: '',
      infoData: {
        title: i18n.global.t('pages_pay_084'),
        list: [],
        subList: []
      }
    })

    watch(() => props.applyNo,
      () => loadDetails()
    )

    const loadFee = async () => {
      state.viewLoading = true
      state.fee = {}
      const res = await _receive.queryGpiFee({
        applyNo: props.applyNo,
        tradeType: 'nation'
      })
      state.viewLoading = false
      if (res.data.succ) {
        state.fee = res.data.data
        state.popVisible = true
      } else {
        message.error(res.data.resMsg)
      }
    }

    const loadGpi = async () => {
      state.popVisible = false
      state.skeletonLoading = true

      const res = await _receive.queryGpiDetail({
        applyNo: props.applyNo,
        tradeType: 'nation'
      })

      if (res.data.succ) {
        state.gpiList = res.data.data.map(item => {
          const gpiDetail = item.gpiDetail.sort((a, b) => a.sort - b.sort)
          return Object.assign({}, item, {
            title: getTradeStatusName(item.gpiStatus, 'gpiStatus'),
            tradeAmtText: `${parseMoney(item.tradeAmt)} ${item.tradeCur}`,
            subList: gpiDetail.map(it => {
              return Object.assign({}, it, {
                title: getTradeStatusName(it.titleCode, 'gpiStatus')
              })
            })
          })
        })
        store.dispatch('getCurList')
      } else {
        message.error(res.data.resMsg)
      }

      state.skeletonLoading = false
    }

    const loadGpiAuth = async () => {
      const res = await _receive.getMerProInfo({ proId: 17 })
      if (res.data.succ) state.gpiShow = res.data.data && res.data.data.status === 'Y'
    }

    const loadDetails = async () => {
      state.spinLoading = true
      state.infoData.list = []
      state.infoData.subList = []
      state.gpiList = []

      const res = await _pay.getOutboundDetail({ applyNo: props.applyNo })
      if (res.data.succ) {
        const list = []
        const { data } = res.data
        const listData = data.fixedTitles.map(item => {
          let img = ''
          let value = data.outboundPayment[item.key]
          let attr = ''
          if (item.key === 'remitAmt') {
            attr = 'remitAmount'
            img = data.outboundPayment['remitAmt'] ? getCountryLogo(data.outboundPayment['remitCurrency']) : null
            value = data.outboundPayment['remitAmt'] ? `${data.outboundPayment['remitCurrency']} ${parseMoney(data.outboundPayment['remitAmt'])}` : '-'
          }
          if (item.key === 'arrivalAmt') {
            attr = 'arrivalAmount'
            img = data.outboundPayment['arrivalAmt'] ? getCountryLogo(data.outboundPayment['arrivalCurrency']) : null
            value = data.outboundPayment['arrivalAmt'] ? `${data.outboundPayment['arrivalCurrency']} ${parseMoney(data.outboundPayment['arrivalAmt'])}` : '-'
          }
          if (item.key === 'feeAmt') {
            attr = 'feeAmt'
            img = data.outboundPayment['feeAmt'] ? getCountryLogo(data.outboundPayment['feeCurrency']) : null
            value = data.outboundPayment['feeAmt'] !== null ? `${data.outboundPayment['feeCurrency']} ${parseMoney(data.outboundPayment['feeAmt'])}` : '-'
          }
          if (item.key === 'amountMark') {
            value = getTradeStatusName(data.outboundPayment['amountMark'], 'amountMark')
          }
          if (item.key === 'bankAcctType') {
            value = getTradeStatusName(data.outboundPayment['bankAcctType'], 'subjectType')
          }
          if (item.key === 'outpayType') {
            value = getTradeStatusName(data.outboundPayment['outpayType'], 'paymentMode')
          }
          return Object.assign({}, item, {
            img,
            value,
            attr,
            name: item.title
          })
        })
        const subList = data.dynamicTitles.map(item => {
          return Object.assign({}, item, {
            name: item.title,
            value: data.outboundPayment[item.key]
          })
        })
        for (const li of listData) {
          if (!['remitCurrency', 'arrivalCurrency', 'feeCurrency'].includes(li.key)) {
            list.push(li)
          }
        }

        state.status = data.outboundPayment.status
        state.infoData.list = list
        state.infoData.subList = subList

        if (data.gpiDetail) {
          state.gpiList = [data.gpiDetail].map(item => {
            const gpiDetail = item.gpiDetail.sort((a, b) => a.sort - b.sort)
            return Object.assign({}, item, {
              title: getTradeStatusName(item.gpiStatus, 'gpiStatus'),
              tradeAmtText: `${parseMoney(item.tradeAmt)} ${item.tradeCur}`,
              subList: gpiDetail.map(it => {
                return Object.assign({}, it, {
                  title: getTradeStatusName(it.titleCode, 'gpiStatus')
                })
              })
            })
          })
        }
      }

      state.spinLoading = false
    }

    loadGpiAuth()

    return {
      loadGpi,
      loadFee,
      ...toRefs(state)
    }
  }
}
</script>
<style lang="less" scoped>
@import '../../../styles/detail-form.less';
</style>

