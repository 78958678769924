<template>
  <div id="gpiDetail">
    <div
      v-for="li in list"
      :key="li.id"
    >
      <a-divider
        dashed
        orientation="left"
        :style="{borderColor: getColor(li.gpiStatus)}"
      >
        <span :style="{color: getColor(li.gpiStatus)}">{{ li.title }}</span>
      </a-divider>
      <div class="detail-item">
        <a-descriptions
          class="flex-1"
          :column="1"
        >
          <a-descriptions-item :label="$t('pages_gpi_007')">{{ li.tradeAmtText }}</a-descriptions-item>
          <a-descriptions-item :label="$t('pages_gpi_008')">{{ li.tradeDate }}</a-descriptions-item>
          <a-descriptions-item :label="$t('pages_gpi_009')">{{ li.remitterBank }}</a-descriptions-item>
          <a-descriptions-item :label="$t('pages_gpi_010')">{{ li.remitter }}</a-descriptions-item>
          <a-descriptions-item
            v-if="from === 'payment'"
            :label="$t('pages_gpi_015')"
          >{{ li.beneBank }}</a-descriptions-item>
          <a-descriptions-item
            v-if="from === 'payment'"
            :label="$t('pages_gpi_016')"
          >{{ li.beneficiary }}</a-descriptions-item>
          <a-descriptions-item :label="$t('pages_gpi_011')">{{ li.beneAcct }}</a-descriptions-item>
        </a-descriptions>
        <a-steps
          size="small"
          direction="vertical"
          class="flex-1"
        >
          <a-step
            v-for="(sub, idx) in li.subList"
            :key="idx"
            :title="sub.title"
            :class="getClass(sub.titleCode)"
          >
            <template #description>
              <div
                v-for="(s, i) in sub.bankInfos"
                :key="i"
                :style="{marginTop: i === 0 ? '12px' : '18px'}"
              >
                <div>{{ $t('pages_gpi_012') }}: {{ s.dealDate }}</div>
                <div>{{ $t('pages_gpi_013') }}: {{ parseMoney(s.amt) + s.currency }}</div>
                <div>{{ $t('pages_gpi_014') }}: {{ s.swiftCode }}</div>
              </div>
            </template>
          </a-step>
        </a-steps>
      </div>
    </div>
    <a-alert
      v-if="list.length !== 0"
      banner
      :show-icon="false"
      :message="$t('pages_gpi_002')"
    />
  </div>
</template>

<script>
import { parseMoney } from '@/util'
export default {
  name: 'GpiDetail',
  props: {
    from: {
      type: String,
      default: () => { return 'receive' }
    },
    list: {
      type: Array,
      default: () => { return [] }
    }
  },
  setup () {
    const getColor = (status) => {
      if (status === '04') return '#FF4D4F'
      if (status === '05') return '#06D569'
      return '#4065E0'
    }

    const getClass = (status) => {
      if (status === '04') return 'step-fail'
      if (status === '05') return 'step-success'
      return 'step-normal'
    }

    return {
      parseMoney,
      getColor,
      getClass
    }
  }
}
</script>

<style lang="less" scoped>
#gpiDetail {
  .flex-1 {
    flex: 1;
  }
  .detail-item {
    display: flex;
    :deep(.ant-steps-item-description) {
      color: rgba(0, 0, 0, .45);
    }
    .step-normal {
      :deep(.ant-steps-item-icon) {
        background: @main-color;
        border-color: @main-color;
        .ant-steps-icon {
          color: #fff !important;
        }
      }
      :deep(.ant-steps-item-title) {
        color: @main-color;
      }
    }
    .step-success {
      :deep(.ant-steps-item-icon) {
        background: @success-color;
        border-color: @success-color;
        .ant-steps-icon {
          color: #fff !important;
        }
      }
      :deep(.ant-steps-item-title) {
        color: @success-color !important;
      }
    }
    .step-fail {
      :deep(.ant-steps-item-icon) {
        background: @error-color;
        border-color: @error-color;
        .ant-steps-icon {
          color: #fff !important;
        }
      }
      :deep(.ant-steps-item-title) {
        color: @error-color !important;
      }
    }
  }
}
</style>
